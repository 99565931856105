
import { page } from '@/api/entity/class'
import { ref } from 'vue'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { Class, PageResponse } from 'momai'

export default {

  setup: () => {
    const totalNum = ref(-1)
    const keyword = ref('')
    const classes = ref<Class[]>([])
    const fetch: FetchFunction = (pageData, loadEnd) => {
      page({
        pageData,
        keyword: keyword.value
      }).then(({
        total,
        records
      }: PageResponse<Class>) => {
        classes.value.push(...records)
        totalNum.value = total
        loadEnd()
      })
    }
    return {
      classes,
      keyword,
      ...useLoadList({
        fetch,
        total: totalNum,
        list: classes
      })
    }
  }
}
